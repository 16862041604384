import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.HEALTH_SERVER_PREFIX}/test-reports`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    testReportByApplicationId: (applicationId) =>
        `${RESOURCE_ENDPOINT}/test-report-data-by-application-id/${applicationId}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getTestInfoByTestId: (testId: any) =>
        `${RESOURCE_ENDPOINT}/get-test-info/${testId}`,
    getAdviceInvestigationResult: () =>
        `${RESOURCE_ENDPOINT}/getAdviceInvestigationResult`,
    getPrevousTestList: (applicationId: any) =>
        `${RESOURCE_ENDPOINT}/previous-test-list/${applicationId}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    createAndUpdateAllSampleCollectionAndReceived: () =>
        `${RESOURCE_ENDPOINT}/all-sample-collection-and-received`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
};

export default class TestingReportPreparationApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public getAdviceInvestigationResult = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAdviceInvestigationResult();
        return HttpService.get(url, params, headers);
    };

    // public getAdviceInvestigationResult = (testingApplicationId: any, reportType: string ,patientId: any): AxiosPromise<any> => {
    //     const url = endpoints.getAdviceInvestigationResult();
    //     return HttpService.get(url);
    // }

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public testReportByApplicationId = (
        applicationId: any
    ): AxiosPromise<any> => {
        const url = endpoints.testReportByApplicationId(applicationId);
        return HttpService.get(url);
    };

    public getTestInfoByTestId = (testId: any): AxiosPromise<any> => {
        const url = endpoints.getTestInfoByTestId(testId);
        return HttpService.get(url);
    };

    public getPrevousTestList = (applicationId: any): AxiosPromise<any> => {
        const url = endpoints.getPrevousTestList(applicationId);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };

    public createAndUpdateAllSampleCollectionAndReceived = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.createAndUpdateAllSampleCollectionAndReceived();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };
}
