import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import {HttpService} from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.HEALTH_SERVER_PREFIX}/report`;
const endpoints = {
    getTestListReport: () => `${RESOURCE_ENDPOINT}/test-list-report`,
    getReportInvoiceList: () => `${RESOURCE_ENDPOINT}/invoice-report`,
    getAnalysisReportEndPoint: () => `${RESOURCE_ENDPOINT}/analysis-report`,
    getAnalysisTestResultReportEndPoint: () => `${RESOURCE_ENDPOINT}/analysis-report-test-result`,
    getRadiationExposureExternalList: () => `${RESOURCE_ENDPOINT}/radiation-exposure-external-report`,
    getCertificateReport: () => `${RESOURCE_ENDPOINT}/certificate`,
    getRadioactivityTestCertificateReport: () => `${RESOURCE_ENDPOINT}/radioactivity-test-certificate`,
    getRadioactivityTestCertificateResultReport: () => `${RESOURCE_ENDPOINT}/radioactivity-test-certificate-result`,
}

export default class TextConsultancyReportApi {
    public getTestListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTestListReport();
        return HttpService.get(url, params, headers);
    }
    public getInvoiceReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getReportInvoiceList();
        return HttpService.get(url, params, headers);
    }
    public getAnalysisReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAnalysisReportEndPoint();
        return HttpService.get(url, params, headers);
    }
    public getAnalysisTestResultReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAnalysisTestResultReportEndPoint();
        return HttpService.get(url, params, headers);
    }
    public getRadiationExposureExternalReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadiationExposureExternalList();
        return HttpService.get(url, params, headers);
    }
    public getCertificateReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCertificateReport();
        return HttpService.get(url, params, headers);
    }
    public getRadioactivityTestCertificateReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadioactivityTestCertificateReport();
        return HttpService.get(url, params, headers);
    }
    public getRadioactivityTestCertificateResultReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadioactivityTestCertificateResultReport();
        return HttpService.get(url, params, headers);
    }
}
