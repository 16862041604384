import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.HEALTH_SERVER_PREFIX}/health-report`;

const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getTestListReportList: () => `${RESOURCE_ENDPOINT}/test-list-report`,
    getTestServiceRegisterReportList: () =>
        `${RESOURCE_ENDPOINT}/test-service-register-report`,
    getHealthIncomeReportList: () => `${RESOURCE_ENDPOINT}/income-report`,
    getLabSampleCollectionReportList: () =>
        `${RESOURCE_ENDPOINT}/lab-sample-collection-report`,
    getDailyServiceIncomeReportList: () =>
        `${RESOURCE_ENDPOINT}/daily-service-income-report`,
    geServiceStatementReportList: () =>
        `${RESOURCE_ENDPOINT}/service-statement-report`,
    getRefundDetailsReportList: () =>
        `${RESOURCE_ENDPOINT}/refund-details-report`,
};

export default class ReportHealthApi {
    public getExampleReport = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    };

    public getTestListReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getTestListReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getTestServiceRegisterReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getTestServiceRegisterReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getLabSampleCollectionReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getLabSampleCollectionReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getHealthIncomeReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getHealthIncomeReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getDailyServiceIncomeReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getDailyServiceIncomeReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public geServiceStatementReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.geServiceStatementReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getRefundDetailsReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getRefundDetailsReportList();
        return HttpService.post(url, payload, params, headers);
    };
}
