import React, {createContext, useReducer, useEffect} from 'react'

import initialState from './auth.state';
import authReducer from './auth.reducer';
import {
  CLEAR_ALL_AUTH_STATE,
  LOADED_AUTH_STATE,
  LOADED_SCOPE,
  CLEAR_AUTH_STATE,
  LOADED_COMMITTEE
} from './auth.types';
import { HttpService } from "@/services/http.services";
import { LeaseCommitteeApi, OauthApi } from "@/api";
import useRefreshToken from "@/hooks/useRefreshToken";

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatchAuth] = useReducer(authReducer, initialState);
  const refreshToken = useRefreshToken(state.isAuthenticated);

  useEffect(() => {
    if (state.userId) {
      loadScopesInfo(state.userId);
      loadCommitteeInfo(state.userId);
    }
  }, [state.userId]);

  const loadAuthState = async (token: string) => {
    try {
      HttpService.setAccessToken(token);

      OauthApi.loadAuthState()
          .then(res => {

            let isAdmin = false;
            let isUser = false;
            if (res.data.data.user_type === 'SERVICE_RECIPIENT') { // ROLE_USER
              isUser = true;
            }
            else {
              isAdmin = true;
            }

            // User
            dispatchAuth({
              type: LOADED_AUTH_STATE,
              payload: {
                isAuthenticated: true,
                isAdmin: isAdmin,
                isUser: isUser,

                user: res.data.data,
                userId: res.data.data.user_id ? Number(res.data.data.user_id) : null,
                userName: res.data.data.user_name,
                userType: res.data.data.user_type,

                departmentId: res.data.data.department_id ? Number(res.data.data.department_id) : null,
                designationId: res.data.data.designation_id ? Number(res.data.data.designation_id) : null,

                profileId: res.data.data.profile_id ? Number(res.data.data.profile_id) : null,
                profileNameEn: res.data.data.profile_name_en,
                profileNameBn: res.data.data.profile_name_bn,
                profileType: res.data.data.profile_type,
                profileImage: res.data.data.profile_image,

                groupIds: res.data.data.group_ids?.map(item => Number(item)) || [],
                groupNameList: res.data.data.group_name_list,
                groupCodeList: res.data.data.group_code_list,

                roleIds: res.data.data.role_ids?.map(item => Number(item)) || [],
                roleNameList: res.data.data.role_name_list,
                roleCodeList: res.data.data.role_code_list,

                organogramId: res.data.data.organogram_id ? Number(res.data.data.organogram_id) : null,
                organogramIds: res.data.data.organogram_ids?.map(item => Number(item)) || [],
                organogramNameEn: res.data.data.organogram_name_en,
                organogramNameBn: res.data.data.organogram_name_bn,

                organizationId: res.data.data.organization_id ? Number(res.data.data.organization_id) : null,
                organizationIds: res.data.data.organization_ids?.map(item => Number(item)) || [],
                organizationNameEn: res.data.data.organization_name_en,
                organizationNameBn: res.data.data.organization_name_bn,

                instituteOrganizationId: res.data.data.institute_organization_id ? Number(res.data.data.institute_organization_id) : null,
              }
            });
          })
          .catch(err => {
            dispatchAuth({type: CLEAR_ALL_AUTH_STATE });
            dispatchAuth({type: CLEAR_AUTH_STATE });
          });
    }
    catch (err) {
      dispatchAuth({type: CLEAR_ALL_AUTH_STATE});
      dispatchAuth({type: CLEAR_AUTH_STATE});
    }
  }

  const loadScopesInfo = (userId: any) => {
    const payload = {
      'user_id': userId,
    }
    OauthApi.loadUserScopes(payload)
        .then(res => {
          dispatchAuth({
            type: LOADED_SCOPE,
            payload: {
              scopes: res.data.data,
            }
          });
        });
  }

  const loadCommitteeInfo = (userId: any) => {
    LeaseCommitteeApi.getCommitteeMemberByUserId(userId)
        .then(res => {
          dispatchAuth({
            type: LOADED_COMMITTEE,
            payload: {
              committeeIds: res.data.data.committeeIds,
            }
          });
        });
  }

  const clearAuthState = () => {
    dispatchAuth({type: CLEAR_ALL_AUTH_STATE});
    dispatchAuth({type: CLEAR_AUTH_STATE});
  }

  const setLoading = (isShow:boolean): void => {
    if(isShow){
      dispatchAuth({
        type: 'SET_LOADING',
        payload: {
          loading: true
        }
      });
    }
    else {
      dispatchAuth({
        type: 'SET_LOADING',
        payload: {
          loading: false
        }
      });
    }
  }

  return (
      <AuthContext.Provider value={{ ...state, dispatchAuth, loadAuthState, clearAuthState, setLoading}}>
        {children}
      </AuthContext.Provider>
  );
};

export default AuthProvider;
