import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.HEALTH_SERVER_PREFIX}/testing-application`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getConsultancyTestingApplicationList: () =>
        `${RESOURCE_ENDPOINT}/getConsultancyTestingApplicationList`,
    // getTestingApplicationByThyroidHistoryWise: () =>
    //     `${RESOURCE_ENDPOINT}/getTestingApplicationByThyroidHistoryWise`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getApplicationWithPatient: (id: any) =>
        `${RESOURCE_ENDPOINT}/getApplicationWithPatient/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    getApprovedApplications: () =>
        `${RESOURCE_ENDPOINT}/getApprovedApplications`,
    applicationOnlineEkpayPayment: () =>
        `${RESOURCE_ENDPOINT}/application-online-payment`,
    getGrandTotal: () => `${RESOURCE_ENDPOINT}/grand-total`,

    applicationsForTesting: () =>
        `${RESOURCE_ENDPOINT}/applications-for-testing`,

    mPIApplicationListDropdown: () =>
        `${RESOURCE_ENDPOINT}/mpi-application-list`,

    petCTScanApplicationListDropdown: () =>
        `${RESOURCE_ENDPOINT}/pet-ct-scan-application-list`,

    thyroidStudyReportApplicationListDropdown: () =>
        `${RESOURCE_ENDPOINT}/thyroid-study-report-application-list`,

    scintigraphyRecordHistoryApplicationListDropdown: () =>
        `${RESOURCE_ENDPOINT}/scintigraphy-record-history-application-list`,

    applicationsForReport: () =>
        `${RESOURCE_ENDPOINT}/applications-for-report-delivery`,
    getAvailableScheduleDate: (testId: Number, applicantType: String) =>
        `${RESOURCE_ENDPOINT}/available-schedule-date/${testId}/${applicantType}`,
    applicationsForPrescription: () =>
        `${RESOURCE_ENDPOINT}/applications-for-prescription`,
    applicationsForHistory: () =>
        `${RESOURCE_ENDPOINT}/applications-for-history`,
    getTestInformationWithScheduleByTestId: (testId: any) =>
        `${RESOURCE_ENDPOINT}/get-test-information-with-schedule/${testId}`,
    getTestListByApplicationId: (applicationId: any) =>
        `${RESOURCE_ENDPOINT}/test-list-by-application-id/${applicationId}`,
    getApprovedApplicationsForHistory: () =>
        `${RESOURCE_ENDPOINT}/getApprovedApplicationsForHistory`,
    getAvailableApplication: () =>
        `${RESOURCE_ENDPOINT}/getAvailableApplication`,
    getReferredByList: () =>
        `${RESOURCE_ENDPOINT}/get-referred-by-list`,
};

export default class TestingApplicationApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public getConsultancyTestingApplicationList = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getConsultancyTestingApplicationList();
        return HttpService.get(url, params, headers);
    };

    // public getTestingApplicationByThyroidHistoryWise = (
    //     params = {},
    //     headers = {}
    // ): AxiosPromise<any> => {
    //     const url = endpoints.getTestingApplicationByThyroidHistoryWise();
    //     return HttpService.get(url, params, headers);
    // };

    public getApplicationWithPatient = (id: any): AxiosPromise<any> => {
        const url = endpoints.getApplicationWithPatient(id);
        return HttpService.get(url);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };

    public getApprovedApplications = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getApprovedApplications();
        return HttpService.get(url, params, headers);
    };

    public applicationOnlineEkpayPayment = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.applicationOnlineEkpayPayment();
        return HttpService.post(url, payload, params, headers);
    };

    public getGrandTotal = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getGrandTotal();
        return HttpService.get(url, params, headers);
    };

    public applicationsForTesting = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.applicationsForTesting();
        return HttpService.get(url, params, headers);
    };

    public mPIApplicationListDropdown = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.mPIApplicationListDropdown();
        return HttpService.get(url, params, headers);
    };

    public petCTScanApplicationListDropdown = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.petCTScanApplicationListDropdown();
        return HttpService.get(url, params, headers);
    };

    public thyroidStudyReportApplicationListDropdown = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.thyroidStudyReportApplicationListDropdown();
        return HttpService.get(url, params, headers);
    };

    public scintigraphyRecordHistoryApplicationListDropdown = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url =
            endpoints.scintigraphyRecordHistoryApplicationListDropdown();
        return HttpService.get(url, params, headers);
    };

    public applicationsForReport = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.applicationsForReport();
        return HttpService.get(url, params, headers);
    };

    public getAvailableScheduleDate = (
        testId: any,
        applicantType: any
    ): AxiosPromise<any> => {
        const url = endpoints.getAvailableScheduleDate(testId, applicantType);
        return HttpService.get(url);
    };

    public applicationsForPrescription = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.applicationsForPrescription();
        return HttpService.get(url, params, headers);
    };

    public applicationsForHistory = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.applicationsForHistory();
        return HttpService.get(url, params, headers);
    };

    public getTestInformationWithScheduleByTestId = (
        testId: any
    ): AxiosPromise<any> => {
        const url = endpoints.getTestInformationWithScheduleByTestId(testId);
        return HttpService.get(url);
    };

    public getTestListByApplicationId = (
        applicationId: any
    ): AxiosPromise<any> => {
        const url = endpoints.getTestListByApplicationId(applicationId);
        return HttpService.get(url);
    };

    public getApprovedApplicationsForHistory = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getApprovedApplicationsForHistory();
        return HttpService.get(url, params, headers);
    };

    public getAvailableApplication = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAvailableApplication();
        return HttpService.get(url, params, headers);
    };

    public getReferredByList = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getReferredByList();
        return HttpService.get(url, params, headers);
    };
}
