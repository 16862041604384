// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";
import _ExcelApi from "./Cdn/Excel.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";

// Core Server
import _NotificationApi from "./Core/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _HelpApi from "./Core/Help.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Auth/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _ComponentApi from "./Core/Component.api";
import _ApplicantProfileEducationalQualificationApi from "./Core/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Core/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Core/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Core/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Core/ApplicantProfileTrainingSeminarCourse.api";
import _ExamApi from "./Core/Exam.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _CNFAgentRepresentativeApi from "./Core/CNFAgentRepresentative.api";
import _VesselApi from "./Core/Vessel.api";
import _PortApi from "./Core/Port.api";
import _CommitteeMemberInfoApi from "./Core/CommitteeMemberInfo.api";
import _LeaseCommitteeApi from "./Core/Committee.api";
import _UnitApi from "./Core/Unit.api";
import _ProjectApi from "./Core/Project.api";
import _PaymentApi from "./Core/Payment.api";
import _ExchangeRateApi from "./Core/ExchangeRate.api";
import _TermConditionApi from "./Core/TermCondition.api";

// TestConsultancy
import _ExportTestConsultancyApi from "./Health/ExportTestConsultancy.api";
import _ImportTestConsultancyApi from "./Health/ImportTestConsultancy.api";
import _InventoryApi from "./Health/Inventory.api";
import _DoctorApi from "./Health/Doctor.api";
import _MedicineApi from "./Health/Medicine.api";
import _DivisionApi from "./Health/Division.api";
import _PatientApi from "./Health/Patient.api";
import _InvestigationTypeApi from "./Health/InvestigationType.api";
import _TestApi from "./Health/Test.api";
import _FrontEndTestListApi from "./Health/FronTendTestListApi.api";
import _DateWiseTestApi from "./Health/DateWiseTest.api";
import _WeekendInformationApi from "./Health/WeekendInformation.api";
import _HolidayInformationApi from "./Health/HolidayInformation.api";
import _TestParameterApi from "./Health/TestParameter.api";
import _TemplateApi from "./Health/Template.api";
import _ServiceApplicationApi from "./Health/ServiceApplication.api";
import _TestingApplicationApi from "./Health/TestingApplication.api";
import _TestingApplicationTestInfoApi from "./Health/TestingApplicationTestInfo.api";
import _TestingFeeCollectionApi from "./Health/TestingFeeCollection.api";
import _TestingFeeRefundApi from "./Health/TestingFeeRefund.api";
import _AttributeApi from "./Health/Attribute.api";
import _AttributeItemApi from "./Health/AttributeItem.api";
import _TestServiceMapApi from "./Health/TestServiceMap.api";
import _VatAitApi from "./Health/VatAit.api";
import _InvoiceApi from "./Health/Invoice.api";
import _MOUApi from "./Health/MOU.api";
import _ReportPreparationApi from "./Health/ReportPreparation.api";
import _ReportDeliveryApi from "./Health/ReportDelivery.api";
import _TestConsultancyReportApi from "./Health/TestConsultancyReportApi.api";
import _RefundApi from "./Health/Refund.api";
import _MethodologyApi from "./Health/Methodology.api";
import _DiscountPolicyApi from "./Health/DiscountPolicy.api";
import _CategoryApi from "./Health/Category.api";
import _SubCategoryApi from "./Health/SubCategory.api";
import _DoctorConsultancyApplicationApi from "./Health/DoctorConsultancyApplication.api";
import _DoctorConsultancyScheduleApi from "./Health/DoctorConsultancySchedule.api";
import _DoctorConsultancyRescheduleApi from "./Health/DoctorConsultancyReschedule.api";
import _PrescriptionApi from "./Health/Prescription.api";
import _ThyroidPatientHistoryApi from "./Health/ThyroidPatientHistory.api";
import _DoctorAppointmentApi from "./Health/DoctorAppointment.api";
import _RadiotherapyHistoryApi from "./Health/RadiotherapyHistory.api";
import _ThyroidFollowUpApi from "./Health/ThyroidFollowUp.api";
import _RadiotherapyPrescriptionApi from "./Health/RadiotherapyPrescription.api";
import _ScintigaraphyRecordHistoryApi from "./Health/ScintigaraphyRecordHistory.api";
import _ThyroidStudyReportApi from "./Health/ThyroidStudyReport.api";
import _TestRefundApi from "./Health/TestRefund.api";

import _LetterApi from "./Health/Letter.api";
import _Cancellation from "./Health/Cancellation.api";
import _FeeCollection from "./Health/FeeCollection.api";
import _ReceiveLetterApi from "./Health/ReceiveLetter.api";
import _SampleReceiveApi from "./Health/SampleReceive.api";
import _ServiceApplicationAdditionalAttachmentApi from "./Health/ServiceApplicationAdditionalAttachment.api";
import _VisitPaymentApi from "./Health/VisitPayment.api";
import _VisitRefundApi from "./Health/VisitRefund.api";
import _TestingScheduleApi from "./Health/TestingSchedule.api";
import _TestingScheduleTestInfoApi from "./Health/TestingScheduleTestInfo.api";
import _TestingReportPreparationApi from "./Health/TestingReportPreparation.api";

import _ClinicalRecordApi from "./Health/ClinicalRecord.api";
import _PetCTScanApi from "./Health/PetCTScan.api";
import _PlantDiseaseApi from "./Health/PlantDisease.api";
import _TldApi from "./Health/Tld.api";
import _TestPaymentApi from "./Health/testPaymentapi";
import _DashboardApi from "./Health/Dashboard.api";

//import _HealthReportApi from "./Health/HealthReport.api";

import _ReportHealthApi from "./Health/ReportHealthApi.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";
import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _WebsocketApi from "./Util/Websocket.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();
export const ExcelApi = new _ExcelApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const ServiceApi = new _ServicesApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();
export const InstituteHeadApi = new _InstituteHeadApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const HelpApi = new _HelpApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const ComponentApi = new _ComponentApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const ExamApi = new _ExamApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const CNFAgentRepresentativeApi = new _CNFAgentRepresentativeApi();
export const VesselApi = new _VesselApi();
export const PortApi = new _PortApi();
export const CommitteeMemberInfoApi = new _CommitteeMemberInfoApi();
export const LeaseCommitteeApi = new _LeaseCommitteeApi();
export const UnitApi = new _UnitApi();
export const ProjectApi = new _ProjectApi();
export const PaymentApi = new _PaymentApi();
export const ExchangeRateApi = new _ExchangeRateApi();
export const TermConditionApi = new _TermConditionApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Util
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const WebsocketApi = new _WebsocketApi();

// TestConsultancy Server
export const ExportTestConsultancyApi = new _ExportTestConsultancyApi();
export const ImportTestConsultancyApi = new _ImportTestConsultancyApi();
export const InventoryApi = new _InventoryApi();
export const DoctorApi = new _DoctorApi();
export const MedicineApi = new _MedicineApi();
export const DivisionApi = new _DivisionApi();
export const PatientApi = new _PatientApi();
export const InvestigationTypeApi = new _InvestigationTypeApi();
export const TestApi = new _TestApi();
export const FrontEndTestListApi = new _FrontEndTestListApi();
export const DateWiseTestApi = new _DateWiseTestApi();
export const WeekendInformationApi = new _WeekendInformationApi();
export const HolidayInformationApi = new _HolidayInformationApi();
export const TestParameterApi = new _TestParameterApi();
export const TemplateApi = new _TemplateApi();
export const VatAitApi = new _VatAitApi();
export const MOUApi = new _MOUApi();
export const InvoiceApi = new _InvoiceApi();
export const ServiceApplicationApi = new _ServiceApplicationApi();
export const TestingApplicationApi = new _TestingApplicationApi();
export const TestingApplicationTestInfoApi = new _TestingApplicationTestInfoApi();
export const TestingFeeCollectionApi = new _TestingFeeCollectionApi();
export const TestingFeeRefundApi = new _TestingFeeRefundApi();
export const AttributeApi = new _AttributeApi();
export const AttributeItemApi = new _AttributeItemApi();
export const TestServiceMapApi = new _TestServiceMapApi();
export const ReportPreparationApi = new _ReportPreparationApi();
export const ReportDeliveryApi = new _ReportDeliveryApi();
export const TestConsultancyReportApi = new _TestConsultancyReportApi();
export const RefundApi = new _RefundApi();
export const MethodologyApi = new _MethodologyApi();
export const DiscountPolicyApi = new _DiscountPolicyApi();
export const CategoryApi = new _CategoryApi();
export const SubCategoryApi = new _SubCategoryApi();
export const DoctorConsultancyApplicationApi = new _DoctorConsultancyApplicationApi();
export const DoctorConsultancyScheduleApi = new _DoctorConsultancyScheduleApi();
export const DoctorConsultancyRescheduleApi = new _DoctorConsultancyRescheduleApi();
export const PrescriptionApi = new _PrescriptionApi();
export const ThyroidPatientHistoryApi = new _ThyroidPatientHistoryApi();
export const RadiotherapyHistoryApi = new _RadiotherapyHistoryApi();
export const ThyroidFollowUpApi = new _ThyroidFollowUpApi();
export const RadiotherapyPrescriptionApi = new _RadiotherapyPrescriptionApi();
export const ScintigaraphyRecordHistoryApi = new _ScintigaraphyRecordHistoryApi();
export const LetterApi = new _LetterApi();
export const ReceiveLetterApi = new _ReceiveLetterApi();
export const CancellationApi = new _Cancellation();
export const FeeCollectionApi = new _FeeCollection();
export const SampleReceiveApi = new _SampleReceiveApi();
export const DoctorAppointmentApi = new _DoctorAppointmentApi();
export const ServiceApplicationAdditionalAttachmentApi = new _ServiceApplicationAdditionalAttachmentApi();
export const VisitPaymentApi = new _VisitPaymentApi();
export const VisitRefundApi = new _VisitRefundApi();
export const TestingScheduleApi = new _TestingScheduleApi();
export const TestingScheduleTestInfoApi = new _TestingScheduleTestInfoApi();
export const TestingReportPreparationApi = new _TestingReportPreparationApi();
export const ClinicalRecordApi = new _ClinicalRecordApi();
export const PetCTScanApi = new _PetCTScanApi();
export const PlantDiseaseApi = new _PlantDiseaseApi();
export const TldApi = new _TldApi();
export const TestPaymentApi = new _TestPaymentApi();
export const ThyroidStudyReportApi = new _ThyroidStudyReportApi();
//export const HealthReportApi = new _HealthReportApi();
export const ReportHealthApi = new _ReportHealthApi();
export const DashboardApi = new _DashboardApi();
export const TestRefundApi = new _TestRefundApi();

